<template>
  <!-- <AnimateHeight> -->
  <div>
    <div v-if="showBalance">
      <h2 class="primary-text text-xl font-bold">
        {{ (balance + creditBalance) | formatMoney }}
      </h2>
      <p class="text-grey text-sm">Total balance</p>
      <div class="other-balance">
        <Hr />
        <h2 class="text-dark text-lg font-semibold mt-4 md:hidden">
          {{ creditBalance | formatMoney }}
        </h2>
        <p class="text-sm text-grey md:hidden">Your credit balance
          <span
                v-if="creditBalance"
                class="cursor-pointer"
                v-tooltip="{
                  content: getCreditFinancer(),
                  placement:'top',
                  trigger:'click',
                  autiHide: true
                }"
              >
                <svg-icon
                  class="inline-block"
                  color="#6956C8"
                  size="15"
                  type="mdi"
                  :path="svgPath.info"
                ></svg-icon>
              </span>
        </p>
        <h2 class="text-dark text-lg font-semibold mt-6">
          {{ balance | formatMoney }}
        </h2>
        <p class="text-sm text-grey">Your wallet balance</p>
      </div>
      <div class="flex justify-end">
        <div class="flex items-center">
          <p
            class="cursor-pointer mr-6 text-sm font-semibold text-grey"
            :class="[
              eligibletoWithdraw && 'opacity-10',
              eligibletoWithdraw && 'pointer-events-none',
            ]"
            @click="$emit('withdraw')"
          >
            Withdraw
          </p>
          <SecondaryButton text="Add money" @click="$emit('fund')" />
        </div>
      </div>
    </div>
    <Hr class="my-3" />
    <div>
      <p class="pb-4 font-semibold text-grey">Wallet history</p>
      <div class="flex flex-wrap justify-end mb-4">
        <DatePicker
          class="mr-4"
          v-model="range"
          is-range
          :model-config="modelConfig"
          :masks="masks"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <Card
              class="flex items-center border searchContainer w-full"
              p="px-1.5 py-1"
            >
              <button
                class="px-2 py-1 focus:outline-none"
                @click="togglePopover()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <path
                    d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <p
                v-if="inputValue.start === null"
                @click="togglePopover()"
                class="text-grey"
              >
                Date filter
              </p>
              <p v-else @click="togglePopover()" class="text-sm text-grey">
                {{ inputValue.start }} - {{ inputValue.end }}
              </p>
            </Card>
          </template>
        </DatePicker>
        <Button
          text="Search"
          p="px-3 py"
          fontSize="text-sm"
          @click="handleSearch"
        />
      </div>
      <div class="container-body scrollbar p-2">
        <transition name="fade" mode="out-in">
          <div v-if="loading" key="load">
            <div v-for="i in 5" :key="i">
              <div class="grid grid-cols-2">
                <div>
                  <Skeleton height="1rem" width="70%" />
                  <Skeleton height="0.8rem" width="90%" />
                  <!-- <p class="text-sm">12th Dec. 2021 | 10.00am</p> -->
                </div>
                <div class="flex flex-col items-end">
                  <Skeleton height="1rem" width="70%" />
                  <Skeleton height="0.8rem" width="90%" />
                </div>
              </div>
              <Hr class="my-4" />
            </div>
          </div>
          <div v-else key="data">
            <EmptyState
              description="No funding history to display"
              v-if="!walletHistory.length"
            />
            <div v-for="wallet in walletHistory" :key="wallet.id" v-else>
              <div class="grid grid-cols-2 content-center">
                <div>
                  <p v-if="wallet.product" class="font-semibold capitalize">
                    {{ wallet.product.replace("BNPL", "").replaceAll("_", " ") | capitalize }}
                  </p>
                  <p
                    v-else
                    class="font-semibold"
                    :class="[wallet.walletActivityType.toLowerCase()]"
                  >
                    {{ wallet.walletActivityType | capitalize }}
                  </p>
                  <!-- <p class="font-semibold" v-if="wallet.product">
                    {{ wallet.product.replace(/_/g, " ") | capitalize }}
                  </p> -->
                  <p class="text-sm text-grey">
                    {{ dayjs(wallet.createdAt).format("MMM D YYYY | hh:mmA") }}
                  </p>
                </div>
                <div class="flex flex-col items-end justify-center">
                  <p
                    :class="[
                      wallet.walletActivityType.toLowerCase() === 'credit'
                        ? 'text-success'
                        : 'failed',
                    ]"
                    class="font-semibold"
                  >
                    {{ wallet.amount | formatMoney }}
                  </p>
                </div>
              </div>
              <Hr class="my-2" />
            </div>
            <Pagination
              :handleMore="handleMore"
              :loading="paginateLoading"
              @paginate="handlePagination"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
  <!-- </AnimateHeight> -->
</template>
<script>
import { mapActions, mapState } from "vuex";
import Hr from "@/UI/Hr";
import dayjs from "dayjs";
import AnimateHeight from "@/UI/AnimateHeight";
import { DatePicker } from "v-calendar";
import { Button, SecondaryButton } from "@/UI/Button";
import {  mdiInformationOutline } from "@mdi/js";
export default {
  components: {
    Hr,
    EmptyState: () => import("@/UI/EmptyState"),
    Pagination: () => import("@/UI/Pagination"),
    AnimateHeight,
    DatePicker,
    Button,
    SecondaryButton,
  },
  data: () => ({
    loading: false,
    paginateLoading: false,
    handleMore: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    params: {
      page: 0,
      pageSize: 10,
      from: "",
      to: "",
    },
    svgPath: {
        info: mdiInformationOutline,
      },
  }),
  props: {
    balance: {
      type: Number,
      default: 0,
      required: false,
    },
    creditBalance: {
      type: Number,
      default: 0,
      required: false,
    },
    showBalance: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    ...mapState({
      walletHistory: (state) => state?.dashboard?.instaPayWalletHistory,
      customerTodos: (state) => state?.dashboard?.dashboardData?.customerTodos,
      creditQualification: (state) => state?.userInfo?.creditQualification,
    }),
    eligibletoWithdraw() {
      return this.customerTodos.some((item) => {
        return item.key === "IDENTITY_VERIFICATION";
      });
    },
    dayjs() {
      return dayjs;
    },
  },
  beforeMount() {
    if(this.walletHistory.length === 0){
      this.getWalletHistory();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getInstaWalletHistory"]),
    getWalletHistory(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getInstaWalletHistory({ type: type, params: this.params })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getWalletHistory("paginate");
    },
    getCreditFinancer(){
      return `Credit financed by ${this.creditQualification?.eligibilityCriteriaResult?.loanProviderName}`
    },
    handleSearch() {
      this.params.page = 0;
      this.params.from = this.range.start;
      this.params.to = this.range.end;
      this.getWalletHistory();
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.container-body {
  min-height: 40vh;
  overflow: auto;
}
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}

.status-container {
  border: 5px;
  background-color: #f5f5f5;
}
</style>
