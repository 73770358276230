var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showBalance
        ? _c("div", [
            _c("h2", { staticClass: "primary-text text-xl font-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("formatMoney")(_vm.balance + _vm.creditBalance)
                  ) +
                  " "
              ),
            ]),
            _c("p", { staticClass: "text-grey text-sm" }, [
              _vm._v("Total balance"),
            ]),
            _c(
              "div",
              { staticClass: "other-balance" },
              [
                _c("Hr"),
                _c(
                  "h2",
                  {
                    staticClass:
                      "text-dark text-lg font-semibold mt-4 md:hidden",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatMoney")(_vm.creditBalance)) +
                        " "
                    ),
                  ]
                ),
                _c("p", { staticClass: "text-sm text-grey md:hidden" }, [
                  _vm._v("Your credit balance "),
                  _vm.creditBalance
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.getCreditFinancer(),
                                placement: "top",
                                trigger: "click",
                                autiHide: true,
                              },
                              expression:
                                "{\n                content: getCreditFinancer(),\n                placement:'top',\n                trigger:'click',\n                autiHide: true\n              }",
                            },
                          ],
                          staticClass: "cursor-pointer",
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "inline-block",
                            attrs: {
                              color: "#6956C8",
                              size: "15",
                              type: "mdi",
                              path: _vm.svgPath.info,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "h2",
                  { staticClass: "text-dark text-lg font-semibold mt-6" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm._f("formatMoney")(_vm.balance)) + " "
                    ),
                  ]
                ),
                _c("p", { staticClass: "text-sm text-grey" }, [
                  _vm._v("Your wallet balance"),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "flex justify-end" }, [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "cursor-pointer mr-6 text-sm font-semibold text-grey",
                      class: [
                        _vm.eligibletoWithdraw && "opacity-10",
                        _vm.eligibletoWithdraw && "pointer-events-none",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.$emit("withdraw")
                        },
                      },
                    },
                    [_vm._v(" Withdraw ")]
                  ),
                  _c("SecondaryButton", {
                    attrs: { text: "Add money" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("fund")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("Hr", { staticClass: "my-3" }),
      _c("div", [
        _c("p", { staticClass: "pb-4 font-semibold text-grey" }, [
          _vm._v("Wallet history"),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end mb-4" },
          [
            _c("DatePicker", {
              staticClass: "mr-4",
              attrs: {
                "is-range": "",
                "model-config": _vm.modelConfig,
                masks: _vm.masks,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var inputValue = ref.inputValue
                    var togglePopover = ref.togglePopover
                    return [
                      _c(
                        "Card",
                        {
                          staticClass:
                            "flex items-center border searchContainer w-full",
                          attrs: { p: "px-1.5 py-1" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "px-2 py-1 focus:outline-none",
                              on: {
                                click: function ($event) {
                                  return togglePopover()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "16",
                                    height: "16",
                                    fill: "none",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z",
                                      fill: "#9D9D9D",
                                      stroke: "#9D9D9D",
                                      "stroke-width": ".1",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z",
                                      fill: "#9D9D9D",
                                      stroke: "#9D9D9D",
                                      "stroke-width": ".1",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          inputValue.start === null
                            ? _c(
                                "p",
                                {
                                  staticClass: "text-grey",
                                  on: {
                                    click: function ($event) {
                                      return togglePopover()
                                    },
                                  },
                                },
                                [_vm._v(" Date filter ")]
                              )
                            : _c(
                                "p",
                                {
                                  staticClass: "text-sm text-grey",
                                  on: {
                                    click: function ($event) {
                                      return togglePopover()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(inputValue.start) +
                                      " - " +
                                      _vm._s(inputValue.end) +
                                      " "
                                  ),
                                ]
                              ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.range,
                callback: function ($$v) {
                  _vm.range = $$v
                },
                expression: "range",
              },
            }),
            _c("Button", {
              attrs: { text: "Search", p: "px-3 py", fontSize: "text-sm" },
              on: { click: _vm.handleSearch },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "container-body scrollbar p-2" },
          [
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _vm.loading
                ? _c(
                    "div",
                    { key: "load" },
                    _vm._l(5, function (i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          _c("div", { staticClass: "grid grid-cols-2" }, [
                            _c(
                              "div",
                              [
                                _c("Skeleton", {
                                  attrs: { height: "1rem", width: "70%" },
                                }),
                                _c("Skeleton", {
                                  attrs: { height: "0.8rem", width: "90%" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-col items-end" },
                              [
                                _c("Skeleton", {
                                  attrs: { height: "1rem", width: "70%" },
                                }),
                                _c("Skeleton", {
                                  attrs: { height: "0.8rem", width: "90%" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("Hr", { staticClass: "my-4" }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { key: "data" },
                    [
                      !_vm.walletHistory.length
                        ? _c("EmptyState", {
                            attrs: {
                              description: "No funding history to display",
                            },
                          })
                        : _vm._l(_vm.walletHistory, function (wallet) {
                            return _c(
                              "div",
                              { key: wallet.id },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-2 content-center",
                                  },
                                  [
                                    _c("div", [
                                      wallet.product
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-semibold capitalize",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("capitalize")(
                                                      wallet.product
                                                        .replace("BNPL", "")
                                                        .replaceAll("_", " ")
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass: "font-semibold",
                                              class: [
                                                wallet.walletActivityType.toLowerCase(),
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("capitalize")(
                                                      wallet.walletActivityType
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "p",
                                        { staticClass: "text-sm text-grey" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .dayjs(wallet.createdAt)
                                                  .format("MMM D YYYY | hh:mmA")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-col items-end justify-center",
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "font-semibold",
                                            class: [
                                              wallet.walletActivityType.toLowerCase() ===
                                              "credit"
                                                ? "text-success"
                                                : "failed",
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatMoney")(
                                                    wallet.amount
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("Hr", { staticClass: "my-2" }),
                              ],
                              1
                            )
                          }),
                      _c("Pagination", {
                        attrs: {
                          handleMore: _vm.handleMore,
                          loading: _vm.paginateLoading,
                        },
                        on: { paginate: _vm.handlePagination },
                      }),
                    ],
                    2
                  ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }